import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Instrument } from '../../classes/instrument';


@Component({
  selector: 'app-instrument-cards',
  standalone: true,
  imports: [ TranslateModule ],
  templateUrl: './instrument-cards.component.html',
  styleUrls: [ './instrument-cards.component.scss' ]
})
export class InstrumentCardsComponent implements OnInit {

  @Input() instruments: Instrument[];
  @Input() allowMultipleChoice: Boolean;

  selectedInstruments: number[] = [];

  constructor() { }

  ngOnInit() {
  }

  selectInstrument(instrument_selected) {
    if (this.allowMultipleChoice) {
      if (this.selectedInstruments.includes(instrument_selected)) {
        this.selectedInstruments.splice(this.selectedInstruments.indexOf(instrument_selected), 1);
      } else {
        this.selectedInstruments.push(instrument_selected);
      }
    } else {
      this.selectedInstruments = [ instrument_selected ];
    }
  }

}
