<div class="center-instruments">
  @for(instrument of instruments; track instrument){
  <div
    class="instrument"
    [class]="{
      'instrument-selected': selectedInstruments.includes(instrument.id)
    }"
  >
    <div>
      @switch (instrument.mapperstring) { @case ('digital_hero') {
      <img src="../../../assets/instrument-dh.svg" alt="" />
      } @default {
      <img src="../../../assets/instrument-world.png" alt="" />
      } }
      <h3>{{ instrument.name }}</h3>
      <p>{{ "__quizPlatform" | translate }}</p>
      <button (click)="selectInstrument(instrument.id)" class="btn-instrument">
        {{ "__seleccionar" | translate }}
      </button>
    </div>
  </div>
  }
</div>
